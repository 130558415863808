.map-controls{
	@include border-radius(.5rem);
	top: 1rem;
	left: 1rem;
	position: absolute;
	z-index: 1;
	background-color: #fff;
	padding: 1rem;
}
#map{
	width: 100%;
	height: 100%;
}
.gm-style-iw{
	.flat-item{
		padding-bottom: 0;
	}
	.flat-item__img{
		height: 16rem;
	}
}
.gm-style .gm-style-iw,
.gm-style .gm-style-iw-d {
	top: 0 !important;
	left: 0 !important;
	width: 28rem !important;
	max-width: 28rem !important;
	padding: 0 !important;
	overflow: hidden !important;
	& > div{
		width: 28rem;
		max-width: 28rem !important;
		overflow: hidden !important;
	}
}
.gm-ui-hover-effect{
	background-color: #fff !important;
	top: 0 !important;
	right: 0 !important;
}
.map-wrap{
	background: $light-grey;
	width: 100%;
	height: 25.5rem;
}