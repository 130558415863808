.price-info {
	@include flex_block(row, nowrap, space-between, center, flex-start);
	font-size: 1.3rem;
	&._lg {
		@include flex_block(row, nowrap, flex-start, center, flex-start);
		font-size: em(30);
		margin-bottom: 3.1rem;
		&>span {
			margin-right: 3rem;
		}
	}
}

.flat-items-list {
	.price-info {
		font-size: 1.4rem;
	}
}

.price-info__roi-value {
	font-weight: 700;
	white-space: nowrap;
	margin-left: 1rem;
	color: $secondary;
}