.modal{
	max-width: 96rem;
	margin: 1rem auto;
}
.mfp-fade {
	&.mfp-bg {
		opacity: 0;
		-webkit-transition: all 0.15s ease-out;
		-moz-transition: all 0.15s ease-out;
		transition: all 0.15s ease-out;
		&.mfp-ready {
			opacity: 0.7;
		}
		&.mfp-removing {
			opacity: 0;
		}
	}
	&.mfp-wrap {
		.mfp-content {
			@include transform(translateY(-5rem));
			opacity: 0;
			-webkit-transition: all 0.15s ease-out;
			-moz-transition: all 0.15s ease-out;
			transition: all 0.15s ease-out;
		}
		&.mfp-ready {
			.mfp-content {
				@include transform(translateY(0));
				opacity: 1;
			}
		}
		&.mfp-removing {
			.mfp-content {
				@include transform(translateY(-5rem));
				opacity: 0;
			}
		}
	}
}
.mfp-bg{
	background-color: #000;
}
a.mfp-close{
	top: 2.5rem;
	right: 2.5rem;
	opacity: 1;
	width: 2.5rem;
	height: 2.5rem;
	padding: 0;
	border: none;
	cursor: pointer;
	svg{
		width: 2.5rem;
		height: 2.5rem;
		pointer-events: none;
	}
	&:active{
		top: 2.5rem;
	}
	&:hover {
		@include transform(scale(1.1));
	}
}