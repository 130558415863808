.p-0{
	padding: 0 !important;
}

.m-0{
	margin: 0 !important;
}
.mb-0{
	margin-bottom: 0 !important;
}
.mb-1{
	margin-bottom: .6rem !important;
}
.mb-2{
	margin-bottom: 1em !important;
}
.mb-3{
	margin-bottom: 1.7em !important;
}
.mb-4{
	margin-bottom: 2.6em !important;
}
.mb-5{
	margin-bottom: 3.2em !important;
}
.pt-0{
	padding-top: 0 !important;
}
.pb-0{
	padding-bottom: 0 !important;
}

.mr-1{
	margin-right: .3rem;
}
.mr-2{
	margin-right: .8rem !important;
}

.ml-0{
	margin-left: 0 !important;
}
.ml-2{
	margin-left: 0.8rem !important;
}

.pl-2{
	padding-left: 2rem;
}

.pr-0{
	padding-right: 0 !important;
}