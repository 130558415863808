@function em($pixels, $context: $browser-context) {
	@return #{$pixels/$context}em;
}
//--------------------------------------------- fonts

$browser-context: 16;

$font-path: "../fonts/";
$main-font: 'Lato',sans-serif;

$font-size-base: 1.6em;
$line-height-base: 1.3;

//--------------------------------------------- colors

$color-text-base: #000;

$primary: #29ac75;
$secondary: #F66E33;

$danger: #e23a59;
$success: #71b451;
$warning : #e9b129;

$dark-grey: rgba(4, 33, 33, 0.7);
$grey: #999;
$light-grey: #e0e0e0;

$body-bg: #f8f8f8;
$link-color: $primary;
$link-hover-color: $secondary;

//--------------------------------------------- media points

$point-1: 1190px;
$point-2: 1023px;
$point-3: 767px;
$point-4: 374px;


//--------------------------------------------- forms

$input-border-color: #213371;
$input-bg-color: #fff;
$input-placeholder-color: $grey;
$base-border-radius: .5rem;