a{
	@include transition();
	color: $link-color;
	text-decoration: none;
	&:hover {
		color: $link-hover-color;
	}
	&[data-toggle=modal] {
		cursor: pointer;
	}
	&.disabled{
		opacity: .5;
		pointer-events: none;
	}
}