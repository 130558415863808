.ui.styled.accordion {
	width: 100%;
	.title{
		padding-right: 4rem;
		position: relative;
		&:after{
			@include arrow(bottom, rgba(0, 0, 0, 0.4), 1rem, .1rem);
			content:'';
			position: absolute;
			top: 50%;
			right: 1.5rem;
			margin-top: -1rem;
		}
		&.active{
			&:after{
				@include transform(rotate(45deg) scale(-1,-1));
			}
		}
	}
}
.content.accordion__content{
	width: 100%;
}
.ui.styled.accordion .title:hover,
.ui.styled.accordion .active.title,
.ui.styled.accordion .accordion .title:hover,
.ui.styled.accordion .accordion .active.title {
	&:after{
		border-color: rgba(0, 0, 0, 0.87);
	}
}