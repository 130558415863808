.flat-item{
	@include flex_block(row, wrap, flex-start, flex-start, flex-start);
	padding: 0 0 1.5rem;
	color: $color-text-base;
	border-bottom: .1rem solid rgba($grey, .3);
	&:last-child {
		border-bottom: none;
	}
	&:hover{
		color: $color-text-base;
	}
}

.flat-item__img{
	width: 100%;
	height: 18rem;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.flat-item__info{
	padding: 1.4rem 1.4rem;
	width: 100%;
}
.flat-item__info-head{
	@include flex_block(row, nowrap, space-between, center, flex-start);
	margin-bottom: .9rem;
	.flat-item__title{
		margin-bottom: 0;
	}
}
.flat-item__title{
	font-weight: 700;
	margin-bottom: 1.5rem;
}