.fotorama__thumb-border {
	position: absolute;
	z-index: 9;
	top: 0;
	left: 0;
	border-style: solid;
	border-color: $primary;
	background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba($primary, 0.1));
}

.fotorama-inner+.dots-list {
	padding-top: 1.5rem;
	.dots-list__title>span {
		white-space: nowrap;
	}
}

.fotorama-inner {
	max-height: 24rem;
}

.fotorama-wrap{
	height: 36.3rem;
}