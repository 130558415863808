.hidden{display: none;}
.tablet-visible,
.tablet-visible-flex{
	display: none !important;
}
.d-block{
	display: block;
}
.d-none{
	display: none !important;
}
.ai-center{
	align-items: center !important;
}