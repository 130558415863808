h1{
	font-size: em(23);
	margin: 0 0 1.2rem;
	.text-sm{
		font-size: em(14,23);
	}
	.text-xs{
		font-size: em(13,23);
	}
	&._lg{
		font-size: em(42);
		margin-bottom: 2.5rem;
	}
}
h2{
	font-weight: 700;
	font-size: em(20);
	color: #042121;
	margin: 0 0 1rem;
	& + p{
		margin-top: -.7rem;
	}
	&._lg{
		font-size: em(30);
	}
}
h3,
.h3{
	font-weight: 700;
	font-size: em(18);
	line-height: em(22,18);
	margin: 0 0 1.2rem;
	.text-xs{
		font-size: em(13,18);
	}
	&.item-section__title{
		overflow: hidden;
		span{
			position: relative;
			&:after {
				content:'';
				top: 50%;
				left: calc(100% + 2rem);
				right: -9999px;
				height: .1rem;
				background-color: rgba(#000, 0.2);
				position: absolute;
			}
		}
	}
}
.title-wrap{
	@include flex_block(row, nowrap, flex-start, center, center);
	margin-bottom: 1.2em;
	h2{
		margin-right: 1.2em;
	}
}
h4{
	font-weight: 700;
	font-size: em(16);
	margin: 0 0 .3rem;
}
h5{
	font-weight: 600;
	font-size: em(14);
	margin: 0 0 .5rem;
}