p {
    margin: 0 0 1.5rem;
    &+p.fw-normal {
        padding-top: 1.2rem;
    }
    &+h3 {
        padding-top: 1.1rem;
    }
    &+.inner-map {
        padding-top: 1rem;
    }
    &:last-of-type {
        margin-bottom: 0;
        &+div:not(.panel),
        p {
            padding-top: 1.5rem;
        }
    }
    &+.columns {
        padding-top: 1em;
    }
    &+ul.content-list {
        padding-top: 1.1em;
    }
    & + .panel{
        margin-top: 3.5em;
    }
}

::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
}

::-webkit-scrollbar-track {
    @include border-radius(.4rem);
    background-color: #fff;
}

::-webkit-scrollbar-thumb {
    @include border-radius(.4rem);
    background-color: $primary;
}

.custom-object-fit_cover {
    background-size: cover !important;
    background-position: center;
    img {
        display: none;
    }
}

blockquote {
    margin: 0;
    padding: 0;
}

q {
    display: block;
    margin-bottom: 1.5rem;
}

cite,
.cite {
    color: $grey;
    font-style: italic;
    font-size: em(12);
    line-height: em(16, 12);
    display: block;
}

time {
    color: $grey;
    font-size: em(14);
    font-style: italic;
}

dl {
    margin: 0;
    padding: 0;
    &+h2 {
        padding-top: 5.6rem;
    }
    &+h3 {
        padding-top: 3.6rem;
    }
    &+h4 {
        padding-top: 1.5rem;
    }
    dt {
        float: left;
        color: $grey;
        margin-right: .6rem;
    }
    dd {
        margin: 0;
    }
    &+p {
        padding-top: 1rem;
    }
    &._xs {
        font-size: 1.2rem;
        dd {
            padding-left: .3rem;
        }
    }
}

address {
    display: block;
    color: $color-text-base;
}