.pag{
	@include flex_block(row, nowrap, flex-start, center, center);
	border-top: .1rem solid rgba(#000, 0.2);
	padding: 1.5rem 1.2rem 0;
}
.pag__item{
	margin: 0 .3rem;
	color: $primary;
}
.pag__link{
	border: .1rem solid $primary;
	color: $primary;
	display: block;
	width: 3.5rem;
	height: 3.5rem;
	text-align: center;
	line-height: 3.3rem;
	cursor: pointer;
	&.active,
	&:hover {
		background-color: $primary;
		color: #fff;
	}
	&._prev,
	&._next{
		@include flex_block(row, nowrap, center, center, center);
	}
}
.pag__arrow{
	width: .7rem;
}