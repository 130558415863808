.icon {
	display: inline-block;
	vertical-align: top;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-arrow-left {
	width:em(13.21);
	height:em(25);
}
.icon-arrow-right {
	width:em(13.21);
	height:em(25);
}
.icon-clip {
	width:em(30);
	height:em(27.2);
}
.icon-close {
	width:em(25);
	height:em(25);
}
.icon-down-arrow-direction {
	width:em(20.9);
	height:em(49);
}
.icon-star {
	width:em(47.94);
	height:em(47.94);
}
