.content-columns{
	@include flex_block(row, nowrap, flex-start, stretch, flex-start);
	height: calc(100vh - 6rem);
	overflow: hidden;
}
.section{
	padding-top: 3rem;
	padding-bottom: 3rem;
}
.d-flex {
	@include flex_block(row, nowrap, flex-start, flex-start, flex-start);
}
.jc-space-between{
	justify-content: space-between;
	-webkit-justify-content: space-between
}
.columns{
	@include flex_block(row, wrap, flex-start, flex-start, flex-start);
	margin: 0 -1rem -2rem;
}
.text-columns{
	@include flex_block(row, wrap, flex-start, flex-start, flex-start);
	margin: 0 -2.5rem -2rem;
	& + .info-item{
		padding-top: .8em;
	}
	& + .text-columns{
		padding-top: 6rem;
	}
	& + .h3{
		padding-top: 1.2em;
	}
	& + h2{
		padding-top: 2.2em;
	}
}
[class^='col-']{
	padding: 0 1rem 2rem;
}
.text-col{
	padding-right: 2.5rem;
	padding-left: 2.5rem;
}
.double-list-columns{
	column-count: 2;
	column-gap: 3rem;
	li{
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid-column;
	}
}
.col-6{
	width: 50%;
}
.col-7{
	width: 58.33%;
}
.col-5{
	width: 41.6%;
}
.col-3{
	width: 25%;
}
[class^='col-']{
	max-width: 100%;
}