.full-info-content{
    @include transition;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
}
.read-full-info-wrap{
    padding-top: 1.5rem;
    font-size: em(18);
	font-weight: 700;
}