.stars-rating{
	@include flex_block(row, nowrap, flex-start, flex-start, flex-start);
	padding: 0;
	height: 1rem;
	&._lg{
		height: 2rem;
		margin: 0 0 .5em;
		.icon-star{
			width: 2rem;
			height: 2rem;
		}
	}
	.icon-star{
		width: 1rem;
		height: 1rem;
		margin-right: .3rem;
		color: rgba(#999, 0.6);
	}
	.checked{
		.icon-star{
			color: #f1dd33;
		}
	}
}
p{
	& + .stars-rating{
		margin-left: 1rem;
	}
}
.starbar{
	height: 1.9rem;
	width: 219px;
	margin-top: .5rem;
	.outer {
		background: url(../i/star-rating/star-grey.svg);
		width: 219px;
	}
	.starbar_w {
		display: block;
		background: url(../i/star-rating/star-yellow.svg);
	}
	.outer,
	.starbar_w{
		background-size: 22px;
		height: 1.9rem;
		position: absolute;
	}
	&._xs{
		height: 1.1rem;
		width: 130px;
		.outer {
			width: 130px;
		}
		.outer,
		.starbar_w{
			background-size: 13px;
			height: 1.1rem;
		}
	}
}
