ul{
	@include reset-ul;
}
ul.content-list{
	@include reset-ul;
	padding-top: .3rem;
	li{
		padding: 0 0 1rem 2rem;
		position: relative;
		&:last-child{
			padding-bottom: 0;
		}
		&:before{
			@include border-radius(50%);
			content:'';
			position: absolute;
			top: .8rem;
			left: 0;
			width: .6rem;
			height: .6rem;
			background-color: #bfbfbf;
		}
	}
}
ul.dots-list{
	&.bb-grey{
		padding-bottom: 1.5em;
		margin-bottom: 1.5em;
	}
}
li.dots-list__item {
	@include flex_block(row, nowrap, space-between, flex-end, flex-start);
	padding: 0;
	margin-bottom: 1.5rem;
	position: relative;
	&:last-child{
		margin-bottom: 0;
	}
	&:before {
		@include transition;
		@include border-radius(.5rem);
		content: '';
		top: -8px;
		left: -15px;
		right: -15px;
		bottom: -8px;
		background-color: #fff;
		position: absolute;
		width: auto;
		height: auto;
		opacity: 0;
		visibility: hidden;
	}
	&:after{
		content:'';
		position: absolute;
		bottom: .3rem;
		left: 0;
		right: 0;
		border-bottom: .2rem dotted $grey;
	}
}
.fotorama{
	li.dots-list__item{
		margin-bottom: 0.8rem;
	}
}
.dots-list__title{
	@include transition;
	margin-bottom: 0;
	padding-right: .7rem;
	flex-grow: 1;
	background-color: #fff;
	& > span {
		background-color: #fff;
		position: relative;
		z-index: 1;
		margin-right: 1rem;
		padding-right: .5rem;
	}
}
.dots-list__value{
	@include transition;
	position: relative;
	z-index: 1;
	background-color: #fff;
	padding-left: .5rem;
	text-align: right;
	font-weight: 700;
}