#header {
	position: relative;
	z-index: 2;
	background-color: #fff;
	font-size: em(14);
	padding: 0 1.5rem;
	box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05), inset 0 -1px 0 rgba(0,0,0,.1);
}
.navbar{
	@include flex_block(row, nowrap, space-between, center, center);
	position: relative;
}
.navbar-left{
	@include flex_block(row, nowrap, flex-start, center, center);
}
.logo{
	@include flex_block(row, nowrap, flex-start, center, center);
	margin-right: 2.5rem;
	span{
		border-left: .1rem solid $grey;
		color: $grey;
		line-height: em(16,14);
		padding: 0 0 0 1rem;
		margin-left: 1rem;
	}
}