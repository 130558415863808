.breadcrumbs{
	@include flex_block(row, nowrap, flex-start, center, center);
	font-size: 1.4rem;
	color: $grey;
	padding-bottom: 1.2em;
	li{
		padding-right: .5rem;
		&:after{
			content:'/';
			margin: 0 0 0 .5rem;
		}
		&:last-child {
			&:after{display: none;}
		}
	}
	a{
		color: $grey;
		&:hover {
			color: $primary;
		}
	}
}