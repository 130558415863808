.tabs{
    @include reset-ul;
    @include flex_block(row, wrap, flex-start, center, center);
}
.tabs__item{
    margin-right: 1.2rem;
}
.tabs__link {
    @include border-radius(.5rem);
    font-size: em(20);
    font-weight: 700;
    color: #fff;
    padding: .5rem 1.5rem;
    display: inline-block;
    vertical-align: top;
    background: $primary;
    &.inactive {
        background: $grey;
    }
    &:hover,
    &:focus{
        color: #fff;
        background: $primary;
    }
}