.nav{
	@include reset-ul;
	@include flex_block(row, nowrap, space-between, center, center);
}
.nav-item{
	margin-right: 1rem;
}
.nav-link{
	color: $color-text-base;
	border: none;
	display: block;
	padding: 2.1rem 1.5rem;
	&._active{
		background-color: $primary;
		color: #fff;
		& + .secondary-nav-wrap {
			display: block;
		}
	}
	&:hover,
	&:focus{
		background-color: #e0e0e0;
		color: $color-text-base;
	}
}
.secondary-nav{
	@include flex_block(row, nowrap, flex-start, center, center);
	position: relative;
	z-index: 1;
	margin: 0 -1.5rem;
}
.secondary-nav__item{
	margin-right: 1rem;
}
.secondary-nav__link{
	@include flex_block(row, nowrap, flex-start, center, center);
	border: none;
	background-color: $primary;
	color: #fff;
	padding: 2.1rem 1.5rem;
	&._past,
	&._future{
		pointer-events: none;
		cursor: default;
	}
	&._current{
		background-color: #f5f5f5;
		color: $primary;
		&:hover,
		&:focus{
			color: $primary;
		}
	}
}
.second-level-menu-opener{display: none;}