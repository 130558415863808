.bb-grey{
	border-bottom: .1rem solid rgba(#000, 0.1);
}
.bt-grey{
	border-top: .1rem solid rgba(#000, 0.1);
	padding-top: 1.2em;
	margin-top: 1.2em;
}
.bb-none{
	border-bottom: none;
}
.rounded-circle{
	@include border-radius(50%);
}
a.bb-dashed{
	border-bottom-style: dashed;
}
span.bb-dashed{
	border-bottom: .1rem dashed rgba($link-color, 0.4);
}
span.bb-solid{
	border-bottom: .1rem solid rgba($link-color, 0.4);
}