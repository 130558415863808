.checkbox{
	& + p{
		padding-top: .9rem;
	}
	& + button{
		margin-top: 1.5rem;
	}
}
[type="checkbox"]{
	&:not(:checked),
	&:checked {
		position: absolute;
		left: -9999px;
		& + label {
			@include border-radius($base-border-radius);
			@include transition;
			position: relative;
			cursor: pointer;
			line-height: 1.5;
			font-size: em(16);
			padding: 0 0 0 3.3rem;
			&:before{
				@include transition;
				@include border-radius(.3rem);
				content: '';
				position: absolute;
				left: 0;
				top: .2rem;
				width: 1.8rem;
				height: 1.8rem;
				background-color: transparent;
				border: .1rem solid rgba(153, 153, 153, 0.4);
			}
			&:after{
				content:'';
				@include transition;
				@include transform(rotate(45deg));
				@include arrow(bottom,#fff,.8rem,.2rem);
				position: absolute;
				top: 0rem;
				left: .8rem;
				width: .7rem;
				height: 1.5rem;
			}
		}
	}
	&:not(:checked) {
		& + label{
			&:after{
				@include transform(rotate(45deg) scale(0));
				opacity: 0;
			}
		}
	}
	&:checked{
		& + label{
			color: $primary;
			&:after{
				@include transform(rotate(45deg) scale(1));
				opacity: 1;
			}
			&:before{
				background-color: $primary;
				border-color: $primary;
			}
		}
		&:hover{
			& + label{
				color: $color-text-base;
			}
		}
	}
	&:disabled{
		&:not(:checked),
		&:checked{
			& + label{
				&:before{
					box-shadow: none;
					border-color: #bbb;
					background-color: #ddd;
				}
			}
		}
		&:checked{
			& + label{
				&:after {
					color: #999;
				}
			}
		}
		& + label {
			color: #aaa;
		}
	}

	&:focus{
		& + label{
			&:before{
				@include box-shadow(0 0 0 .2rem rgba($primary,.25));
				border-color: $primary;
			}
		}
	}
}

.was-validated{
	[type="checkbox"]{
		&:invalid{
			&:not(:checked),
			&:checked{
				& + label{
					&:before{
						border-color: #f00;
					}
				}
			}
		}
	}
}