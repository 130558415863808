.btn {
	@include border-radius(.3rem);
	display: inline-block;
	vertical-align: top;
	white-space: nowrap;
	text-align: center;
	max-width: 100%;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	padding: 0 1.4rem;
	text-decoration: none;
	cursor: pointer;
	font: 700 1.4rem $main-font;
	line-height: 3.8rem;
	height: 4rem;
	position: relative;
	& + .btn{
		margin-left: 1rem;
	}
	&._sm{
		height: 3.5rem;
		line-height: 3.3rem;
	}
	&._lg{
		height: 5rem;
		line-height: 4.8rem;
		padding-right: 3rem;
		padding-left: 3rem;
		font-size: 1.6rem;
	}
	&._primary{
		background-color: $primary;
		color: #fff;
		border: .1rem solid $primary;
		&:hover{
			background-color: darken($primary, 7%);
		}
		&:focus{
			@include box-shadow(0 0 0 3px rgba($primary,.25));
		}
	}
	&._primary-outline{
		background-color: transparent;
		color: $primary;
		border: .1rem solid $primary;
		&:hover{
			background-color: $primary;
			color: #fff;
		}
		&:focus{
			@include box-shadow(0 0 0 3px rgba($primary,.25));
		}
	}
	&:disabled{
		pointer-events: none;
		cursor: default;
		background-image: $grey;
	}
}