.c-grey{
	color: $grey;
}
.c-dark-grey{
	color: $dark-grey;
}
.c-light-grey{
	color: $light-grey;
}
.c-link{
	color: $link-color;
}
.c-danger{
	color: $danger;
}
.c-success{
	color: $success;
}
.c-warning{
	color: $warning;
}
.c-white{
	color: #fff;
}
.c-black{
	color: #000;
}
.c-secondary{
	color: $secondary;
}
.c-base{
	color: $color-text-base;
}
.c-primary{
	color: $primary;
}
.bg-white{
	background: #fff;
}
.bg-grey{
	background: #f8f8f8;
}
.bg-primary{
	background: $primary;
}