.panel{
	@include border-radius(.5rem);
	@include box-shadow(0px 4px 17px rgba(0, 0, 0, 0.15));
	background-color: #fff;
	padding: 3em 2.5em;
	overflow: hidden;
	position: relative;
	z-index: 1;
	&._analysis{
		overflow: visible;
		border: 1px solid rgba(151, 151, 151, 0.38);
	}
}
.panel__tabs{
	position: absolute;
	top: -1.8rem;
	left: 0;
}
@media screen and (max-width:$point-3) {
	.panel{
		padding: 3rem 1.5rem;
	}
}