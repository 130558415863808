.select-icon{
	position: absolute;
	right: 1.6rem;
	bottom: 1.6rem;
	pointer-events: none;
	color: $grey;
}
select{
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
}
select.form-control{
	padding-right: 3.6rem;
}
select::-ms-expand {
	display: none;
}