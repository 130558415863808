#wrapper {
	overflow: hidden;
	position: relative;
	width: 100%;
	min-height: 100vh;
}
.center-holder {
	max-width: 122rem;
	margin: 0 auto;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.content{
	width: calc(100% - 36rem);
	position: relative;
}