* {
	@include box-sizing(border-box);
	max-height: 1000000px;
}
html {
	height: 100%;
	font-size: 62.5%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: none;
	margin: 0;
	font-family: $main-font;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $color-text-base;
	-webkit-font-smoothing: antialiased;
	background-color: $body-bg;
	min-height: 100%;
	font-weight: 400;
	min-width: 102.4rem;
	width: 100%;
	position: relative;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
	display: block;
}
img {
	border-style: none;
	vertical-align: top;
	max-width: 100%;
	height: auto;
}
button {
	@include transition();
}
strong {
	font-weight: 700;
}
input,
textarea,
select {
	@include border-radius(0);
	vertical-align: middle;
}
form,
fieldset {
	margin: 0;
	padding: 0;
	border-style: none;
}
button[disabled],
html input[disabled] {
	cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	&:active,
	&:focus {
		outline: none !important;
	}
}
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
	&:focus {
		outline: none;
		&::-webkit-input-placeholder {
			color: transparent;
		}
		&::-moz-placeholder {
			color: transparent;
		}
		&:-moz-placeholder {
			color: transparent;
		}
		&:-ms-input-placeholder {
			color: transparent;
		}
	}
	&::-webkit-input-placeholder {
		color: $input-placeholder-color;
	}
	&::-moz-placeholder {
		color: $input-placeholder-color;
	}
	&:-moz-placeholder {
		color: $input-placeholder-color;
	}
	&:-ms-input-placeholder {
		color: $input-placeholder-color;
	}
}

input[type=text],
input[type=password],
input[type=file],
input[type=submit],
input[type=search],
input[type=email],
input[type="tel"],
input[type="url"],
textarea {
	@include border-radius(none);
	-webkit-appearance: none;
}
textarea {
	overflow: auto;
	resize: vertical;
	vertical-align: top;
}
input[type="checkbox"],
input[type="radio"] {
	padding: 0;
}
input[type="search"] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	webkit-appearance: none;
}
q {
	quotes: none;
	&:before,
	&:after {
		content: '';
		content: none;
	}
}
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sup {
	top: -0.5em;
}
sub {
	bottom: -0.25em;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}