label{
	display: inline-block;
	vertical-align: top;
	font-weight: 400;
	margin-bottom: .8rem;
}
.form-control{
	@include transition;
	@include border-radius($base-border-radius);
	height: 4rem;
	border: .1rem solid rgba($grey, .4);
	display: block;
	background: #fff;
	width: 100%;
	font: em(16) $main-font;
	line-height: em(20,14);
	padding: .9rem 1.2rem;
	&._lg{
		height: 5rem;
		font-size: em(20);
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
	&:focus{
		@include box-shadow(0 0 0 3px rgba($primary,.25));
		outline: none;
		border-color: $primary;
		position: relative;
		z-index: 1;
	}
}
textarea{
	height: 15rem;
	min-height: 15rem;
	max-height: 15rem;
	resize: none;
}
.map-controls__input-group{
	@include flex_block(row, nowrap, flex-start, center, flex-start);
	input.form-control{
		width: 15rem;
		height: 3.5rem;
		padding: .6rem .8rem;
		margin-right: -.1rem;
		&:first-of-type{
			@include border-radius(.5rem 0 0 .5rem);
		}
		&:last-of-type{
			@include border-radius(0);
		}
	}
	.input-group-text{
		height: 3.5rem;
		padding: .6rem .8rem;
	}
}
.input-group{
	margin-bottom: 1.5rem;
}
.input-group-append{
	.input-group-text {
		@include border-radius(0 $base-border-radius $base-border-radius 0);
	}
}
.input-group-text {
	@include border-radius($base-border-radius);
	@include flex_block(row, nowrap, center, center, center);
	height: 4rem;
	text-align: center;
	white-space: nowrap;
	background-color: #e9ecef;
	padding: .5rem 1.2rem;
	border: .1rem solid rgba($grey, .4);
}
@import "form-components/select";
@import "form-components/dropdown";
@import "form-components/checkbox";
@import "form-components/custom-file";
