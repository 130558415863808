.text-xxs{

}
.text-xs{
	font-size: em(13);
}
.text-sm{
	font-size: em(14);
}
.text-md{
	font-size: em(20);
}
.text-lg{
	font-size: em(37);
}
.text-xlg{
	font-size: em(70);
	line-height: em(70,70);
	font-weight: 300;
}

.fw-bold{
	font-weight: 700 !important;
}
.fw-semibold{
	font-weight: 600 !important;
}
.fw-medium{
	font-weight: 500 !important;
}
.fw-normal{
	font-weight: 400 !important;
}
.fw-light{
	font-weight: 300 !important;
}
.fw-thin{
	font-weight: 100 !important;
}

.text-left{
	text-align: left !important;
}
.text-right{
	text-align: right !important;
}
.text-center {
	text-align: center !important;
}

address{
	font-style: normal;
	font-weight: normal;
}

.text-uppercase{
	text-transform: uppercase;
}

.td-underline{
	text-decoration: underline;
}

.text-muted{
	font-size: 90%;
	color: $grey;
}