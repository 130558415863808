.aside-panel{
	box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05), inset 0 -1px 0 rgba(0,0,0,.1);
	width: 36rem;
	background-color: #fff;
	height: calc(100vh - 6rem);
}
.aside-panel__head{
	padding: 1.5rem 1.4rem 1.5rem;
	border-bottom: .1rem solid rgba($grey, .3);
}
.flat-items-list{
	overflow: auto;
	height: calc(100% - 6.1rem);
}