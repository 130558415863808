.input-file-custom{
	width: 45rem;
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
	& + label {
		@include flex_block(row, nowrap, flex-start, center, center);
		max-width: 80%;
		font-size: 1.25rem;
		font-weight: 700;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
		overflow: hidden;
		padding: 0.625rem 1.25rem;
		margin-bottom: 0;
		.icon{
			margin-right: .5rem;
		}
	}
}

.inputfile:focus + label,
.inputfile.has-focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}